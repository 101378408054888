.card img {
    width: 100%;
    height: 100%;
    border-radius: 10px 10px 0 0;
}

.items {
    border-radius: 10px;
    transition: all 0.3s ease;
}

.items:hover {
    transform: scale(1.03);
}

.card .text {
    padding: 40px 30px;
    
}

.card h1 {
    font-weight: 500;
    font-size: 35px;
    line-height: 30px;
    margin: 20px 0;
    transition: 0.5s;
    text-align: center;
}

.card span {
    text-transform: uppercase;
    font-weight: 500;
    color: #1eb2a6;
}

.card span label {
    margin-left: 10px;
    color: grey;
    font-size: 14px;
}

.card span:nth-last-child(1) label {
    color: #1eb2a6;
}

.card p {
    color: #fff;
    text-align: center;
}

.card .shadow {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

@media screen and (max-width: 1200px) {
    .card p {
        font-size: 15px;
    }
}