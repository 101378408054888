.careerMain {
    background-color: #fdd10b;
    color: #fff;
    padding: 50px 30px;
    border-radius: 10px;
    transition: all 0.3s ease;
    text-align: center;
}

.careerMain:hover {
    transform: scale(1.01);
}

.careerMain h1 {
    color: #2b2e74;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 15px;
}

.careerMain .right form {
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.careerMain input {
    border: none;
    border-radius: 5px;
    border: 2px solid #2b2e74;
    height: 30px;
    outline: none;
    padding: 20px;
    width: 100%;
}

.careerMain button {
    background-color: #2b2e74;
    color: #fdd10b;
    transition: all 0.3s ease;
}

.careerMain button:hover{
    color: #fff;
    transform: scale(1.05);
}

.careerHome {
    text-align: center;
    margin-bottom: 40px;
    padding: 0 20px;
}

.outer {
    margin-bottom: 40px;
    padding: 40px;
}

@media screen and (max-width: 768px) {
    .careerMain .left,
    .careerMain .right {
        padding-right: 15px;
    }
}