@import url("https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700;800&amp;display=swap");

/* 
Yellow : #fdd10b;
Blue : #2b2e74;
*/

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    font-family: "Baloo 2", cursive, sans-serif;
    background-color: #fdd10b;
}

/*------------global-----------*/
.container {
    max-width: 85%;
    margin: auto;
}

.flexSB {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flexSB2 {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.icon {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.3);
    color: #2b2e74;
    transition: 0.5s;
}

.icon:hover {
    cursor: pointer;
    color: #fff;
}

a {
    text-decoration: none;
}

li {
    list-style-type: none;
}

.row {
    width: 50%;
}

button {
    padding: 17px 30px;
    background-color: #fff;
    color: #2b2e74;
    font-weight: 600;
    border: none;
    margin: 30px 10px 0 0;
    border-radius: 3px;
    cursor: pointer;
    transition: 0.5s;
}

button i {
    margin-left: 20px;
}

button:hover {
    box-shadow: none;
}

.primary-btn {
    background-color: #2b2e74;
    color: #fff;
    transition: all 0.3s ease;
}

.primary-btn:hover {
    color: #fdd10b;
    transform: scale(1.05);
}

.secondary-btn {
    background-color: #fdd10b;
    color: #2b2e74;
    transition: all 0.3s ease;
}

.secondary-btn:hover {
    color: #fff;
    transform: scale(1.05);
}

.yellow-color {
    background-color : #fdd10b;
}

.blue-color {
    background-color: #2b2e74;
}

.blue-color-round {
    background-color: #2b2e74;
    border-radius: 50%;
}

.yellow-text {
    color : #fdd10b;
}

.blue-text {
    color: #2b2e74;
}

/*------------global-----------*/
.heading {
    text-align: center;
    padding: 40px 0;
}

.heading h3 {
    font-weight: 800;
    margin-bottom: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 30px;
}

.heading h1 {
    font-size: 60px;
    margin-top: 20px;
    text-transform: capitalize;
    transition: all 0.3s ease;
}

.heading h1:hover {
    transform: scale(1.05);
    cursor: pointer;
}

p {
    line-height: 30px;
    font-size: 18px;
}

.back {
    background-size: cover;
    background-attachment: fixed;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100vh;
    padding-top: 30%;
    padding-right: 50px;
    color: #fff;
    text-align: center;
}

.back h1 {
    font-size: 100px;
    font-weight: 400;
}

.back h2 {
    font-weight: 500;
    font-size: 17px;
    text-transform: uppercase;
}

.margin {
    margin-top: 80vh;
}

.grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
}

.flex {
    display: flex;
}

.grid2 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
}

.outline-btn {
    box-shadow: none;
    border: 2px solid #fdd10b;
    width: 100%;
    transition: 0.5s;
}

.outline-btn:hover {
    background-color: #fdd10b;
    color: #2b2e74;
}

.grid3 {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 10px;
}

.padding {
    padding: 80px 0;
}

.padding2 {
    padding-top: 50px;
    padding-bottom: 100px;
}

.shadow {
    box-shadow: 0 5px 25px -2px rgb(0 0 0 / 6%);
}

.left {
    justify-content: center;
}


@media screen and (max-width: 1200px) {
    nav {
        background-color: #fdd10b;
    }

    .grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .grid2 {
        grid-template-columns: repeat(2, 1fr);
    }

    .row {
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .padding2 {
        padding-top: 0;
    }

    .flexSB2 {
        display: flex;
        flex-direction: column;
    }

    .grid2 {
        grid-template-columns: repeat(1, 1fr);
    }

    .grid {
        grid-template-columns: repeat(3, 1fr);
    }

    .grid3 {
        grid-template-columns: repeat(3, 1fr);
    }

    .back {
        background-position: center;
        padding-top: 50%;
        height: 80vh;
    }

    .row {
        width: 100%;
        margin: 10px;
    }

    .heading h1 {
        font-size: 40px;
    }

    .heading h3 {
        font-size: 20px;
    }
}