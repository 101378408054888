.team .items {
    transition: 0.5s;
}

.team .img {
    position: relative;
}

.team img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.overlay {
    position: absolute;
    bottom: 0;
    z-index: 11;
    display: flex;
    flex-direction: column;
    padding: 20px;
    opacity: 0;
    transition: 0.5s;
}

.overlay i {
    background-color: #000;
    color: #fff;
    margin: 5px;
    transition: 0.5s;
}

.team .details {
    height: 130px;
    margin: 10px 0;
    padding: 15px;
    text-align: center;
    border-radius: 20px;
}

.team .details h2 {
    font-weight: 500;
    font-size: 20px;
    transition: 0.5s;
}

.team .details p {
    font-size: 15px;
    color: #fff;
    margin-top: 10px;
    transition: 0.5s;
}

.team .items:hover {
    color: #fff;
    cursor: pointer;
}

.team .items:hover p {
    color: #fff;
}

.team .items:hover .overlay {
    opacity: 1;
}

.team .items:hover .overlay i {
    background-color: #2b2e74;
    color: #fdd10b;
}

.team .items:hover .overlay i:hover {
    color: #fff;
}
