footer {
    background-color: #2b2e74;
}

footer .container {
    display: grid;
    grid-template-columns: 3fr 2fr 2fr 4fr;
    grid-gap: 30px;
}

@media screen and (max-width: 768px) {
    footer {
        text-align: center;
    }

    footer .container {
        grid-template-columns: repeat(1, 1fr);
    }
}

footer .logo span {
    color: #fff;
    font-size: 14px;
}

footer .logo p {
    color: #fff;
    margin: 20px 10px 15px 0;
    font-size: 15px;
}

footer .logo .icon {
    background-color: #fdd10b;
    margin-right: 10px;
    transition: all 0.3s ease;
}

footer h3 {
    margin-bottom: 40px;
    font-weight: 500;
    color: #fdd10b;
}

footer ul li {
    margin-bottom: 20px;
    transition: 0.3s all ease;
}

footer ul li a {
    margin-bottom: 20px;
    transition: 0.3s all ease;
    color: #fff;
}

footer ul li:hover {
    color: #fdd10b;
    cursor: pointer;
}

footer ul li a:hover {
    margin-bottom: 20px;
    transition: 0.3s all ease;
    color: #fdd10b;
}

footer .link li::before {
    color: #1eb2a6;
    margin-right: 5px;
}

footer .items {
    margin-bottom: 30px;
}

footer .items img {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    margin-right: 10px;
    object-fit: cover;
}

footer .items span,
footer .items i {
    font-size: 12px;
    color: #fdd10b ;
    margin-right: 5px;
    text-transform: capitalize;
}

footer h4 {
    font-weight: 400;
    margin-top: 5px;
}

footer .last ul li {
    display: flex;
    margin-bottom: 20px;
    color: #fff;
    transition: all 0.3s ease;
}

footer .last ul li:hover {
    color: #fdd10b;
}

footer .last i {
    width: 60px;
    font-size: 20px;
    color: #fdd10b;
}

.legal {
    text-align: center;
    padding: 50px 0;
    color: #fdd10b;
    background-color: #1f2050;
}

.legal p {
    font-size: 15px;
}

.legal i {
    color: #1eb2a6;
}

@media screen and (max-width: 768px) { 
    footer .last ul li {
        justify-content: center;
    }
}   