.fieldHome {
    margin-bottom: 20px;
}

.fieldDiv {
    width: 100%;
    text-align: center;
    padding: 1rem
}

.fieldDiv > h2 {
    font-size: 35px;
    padding: 1rem;
    transition: all 0.3s ease;
}

.fieldDiv > div {
    text-align: center;
    padding-bottom: 20px;
}

.fieldDiv > div > img {
    border: 3px solid;
    width: 50%;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    transition: 0.3s all ease;
}

.fieldDiv > div > img:hover {
    transform: scale(1.01);
}