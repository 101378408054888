.testimonal .items {
    padding: 30px;
    transition: 0.5s;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.testimonal .img {
    position: relative;
}

.testimonal img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.testimonal i {
    position: absolute;
    bottom: 0;
    left: 60px;
    background-color: #fdd10b;
    color: #2b2e74;
}

.testimonal .name {
    margin: 10px;
    text-align: center;
    width: 100%;
}

.testimonal .name h2 {
    font-size: 25px;
}

.testimonal .name span {
    font-size: 18px;
    color: #fdd10b;
}

.testimonal p {
    font-size: 15px;
    margin-top: 20px;
    color: #fff;
    transition: 0.5s;
}

.testimonal .items:hover {
    cursor: pointer;
}


.testimonal .items:hover i {
    color: #fff;
}