/* 
Yellow : #fdd10b;
Blue : #2b2e74;
*/

.outer-div {
    height: 75vh;
    background-color: #2b2e74;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.data-left {
    flex: 0.5
}

.data-right {
    flex: 0.5
}

.main-data {
    padding: 20px;
    color: #fdd10b;
}

.main-data h3 {
    font-weight: 800;
    margin-bottom: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 30px;
}

.main-data h1 {
    font-size: 60px;
    margin-top: 20px;
    text-transform: capitalize;
    transition: all 0.3s ease;
}

.main-data h1:hover {
    transform: scale(1.05);
    cursor: pointer;
}

p {
    line-height: 30px;
    font-size: 18px;
    color: #fdd10b;
}

img {
    width: 100%; /* or any custom size */
    height: 100%; 
    object-fit: contain;
}

@media screen and (max-width: 1000px) {
    .outer-div {
        flex-direction: column;
        text-align: center;
    }   
}

@media screen and (max-width: 500px) {
    .main-data h1 {
        font-size: 50px;
    }

    .main-data h3 {
        font-size: 25px;
    }
}