.limiter {
    width: 100%;
    margin: 0 auto;
    text-align: center;
}

.container-table100 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 33px 30px;
}

.wrap-table100 {
    width: 960px;
    border-radius: 10px;
    overflow: hidden;
}

.table,
.row2 {
    width: 100% !important;
}

.table,
.row3 {
    width: 100% !important;
}

.table {
    display: table;
    margin: 0;
}

.row2.header {
    color: #fff;
    background: #2b2e74;
}

.row3.header {
    color: #fff;
    background: #2b2e74;
}

.row2 {
    display: table-row;
    background: #fff;
}

.row3 {
    display: table-row;
    background: #fff;
}

.row2 .cell:nth-child(1) {
    width: 480px;
    padding-left: 40px;
}

.row3 .cell:nth-child(1) {
    width: 320px;
    padding-left: 40px;
}

.row2 .cell:nth-child(2) {
    width: 480px;
}

.row3 .cell:nth-child(2) {
    width: 320px;
}

.row3 .cell:nth-child(3) {
    width: 320px;
}

.row2.header .cell {
    font-size: 18px;
    color: #fff;
    line-height: 1.2;
    font-weight: unset !important;
    padding-top: 19px;
    padding-bottom: 19px;
}

.row3.header .cell {
    font-size: 18px;
    color: #fff;
    line-height: 1.2;
    font-weight: unset !important;
    padding-top: 19px;
    padding-bottom: 19px;
}

.cell {
    display: table-cell;
}

.row2 .cell {
    font-size: 15px;
    color: #666;
    line-height: 1.2;
    font-weight: unset !important;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #f2f2f2;
}  

.row3 .cell {
    font-size: 15px;
    color: #666;
    line-height: 1.2;
    font-weight: unset !important;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #f2f2f2;
}  