.aboutHome {
    height: auto;
}

.aboutHome .heading {
    text-align: left;
    padding: 0;
}

.aboutHome .left img {
    width: 100%;
    height: 80%;
    object-fit: cover;
    background-size: contain;
    background-repeat: no-repeat;
    vertical-align: center;
}

.aboutHome .right {
    padding: 80px 50px;
}

.aboutHome .items {
    margin-top: 50px;
}

.aboutHome .item {
    margin-top: 30px;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: 0.5s;
    border-radius: 10px;
}

.aboutHome .img {
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}

.aboutHome img {
    width: 70px;
    height: 70px;
}

.aboutHome .item h2 {
    font-size: 20px;
    margin-bottom: 15px;
}

.aboutHome .item p {
    color: #fff;
}

.aboutHome .item:hover {
    background-color: #fff;
    cursor: pointer;
}

.aboutHome .item:hover p {
    color: black;
}

/*----------awrapper------------*/
.awrapper {
    /* background-image: url("../../../public/images/awrapper.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; */
    /* height: 30vh; */
    color: #fff;
    background-color: #2b2e74;
}

.awrapper .box {
    padding: 70px 0;
}

.awrapper img {
    margin-right: 30px;
}

.awrapper h1 {
    font-size: 50px;
}

.awrapper h3 {
    font-size: 20px;
    font-weight: 500;
}

@media screen and (max-width: 1050px) {
    .aboutHome .container {
        flex-direction: column-reverse;
    }

    .awrapper h1 {
        font-size: 30px;
    }
    
    .awrapper h3 {
        font-size: 16px;
        font-weight: 500;
    }
}

/*----------awrapper------------*/
@media screen and (max-width: 768px) {
    .aboutHome .container {
        flex-direction: column-reverse;
    }

    .aboutHome .row {
        width: 100%;
    }

    .awrapper {
        height: 20vh;
    }

    .awrapper .box {
        padding: 35px 0;
    }

    .awrapper .img {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .awrapper img {
        height: 40px;
        width: 40px;
        margin-right: 5px;
    }
    
    .awrapper h1 {
        font-size: 20px;
    }
    
    .awrapper h3 {
        font-size: 8px;
        font-weight: 500;
    }

    .aboutHome .heading {
        text-align: center;
    }

    .aboutHome .heading h1 {
        font-size: 35px;
    }

    .aboutHome .right {
        padding:0;
    }
}