.price .items {
    padding: 40px;
    text-align: center;
}

.price h1 {
    font-size: 50px;
    color: #fdd10b;
}

.price p ul li {
    font-size: 20px;
    margin-right: 5px;
    color: #fff;
}

.price p {
    color: grey;
    margin: 40px 0;
}