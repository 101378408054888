/* For Head */

.head {
    padding: 10px 0;
    color: #fff;
}

.logo {
    height: 100%;
    cursor: pointer;
}

.logo img {
    width: 250px;
    margin-left: -5px;
    margin-top: 8px;
}

.head .icon {
    margin-left: 6px;
}

/* For Head and Navbar */

header {
    background-color: rgba(255, 255, 255, 0.2);
    margin: 0 30px;
    background-color: #fdd10b;
}

header .nav {
    display: flex !important;
}
  
header li {
    margin-right: 40px;
}
  
header ul {
    padding: 30px 20px;
}
  
header ul li a {
    color: #2b2e74;
    font-weight: 500;
    transition: 0.5s;
    cursor: pointer;
}
  
header ul li a:hover {
    color: #fff; 
}
  
.start {
    font-weight: 600;
    background-color: #fdd10b;
    padding: 30px 70px;
    color: #2b2e74;
    clip-path: polygon(10% 0%, 100% 0, 100% 100%, 0% 100%);
    transition: all 0.3s ease;
}

.start a{
    color: #2b2e74;
}

.start:hover{
    font-size: 17px;
    cursor: pointer;
}
  
.toggle {
    display: none;
}

.social {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    margin-left: 10px;
}

.social a i {
    color: #fdd10b;
}

.social a i:hover {
    background-color: #fdd10b;
    color: #2b2e74;
}

/* For Navbar */

@media screen and (max-width: 1200px) {
    .start {
        clip-path: none;
    }

    header {
        margin: 0;
        background-color: #2b2e74;;
        position: relative;
    }
    header ul.flexSB {
        display: none;
    }
    header ul li {
        margin-bottom: 20px;
    }
    .toggle {
        background-color: #fdd10b;
        display: block;
        background: none;
        color: #fff;
        font-size: 30px;
        position: absolute;
        right: 0;
        top: -20px;
    }
    .mobile-nav {
        position: absolute;
        top: 7vh;
        left: 0;
        width: 100%;
        background-color: #fdd10b;;
    }
}

@media screen and (max-width: 768px) {
    .logo img {
        width: 140px;
        /* margin-right: 5px; */
    }
    .head i {
        width: 33px !important;
        height: 33px !important;
    }

    /* .start {
        clip-path: none;
    }

    header {
        margin: 0;
        background-color: #2b2e74;;
        position: relative;
    }
    header ul.flexSB {
        display: none;
    }
    header ul li {
        margin-bottom: 20px;
    }
    .toggle {
        background-color: #fdd10b;
        display: block;
        background: none;
        color: #fff;
        font-size: 30px;
        position: absolute;
        right: 0;
        top: -20px;
    }
    .mobile-nav {
        position: absolute;
        top: 7vh;
        left: 0;
        width: 100%;
        background-color: #fdd10b;;
    } */
}