.coursesCard {
    padding: 50px 0;
}

.coursesCard .items {
    padding: 30px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.coursesCard .img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    padding: 15px;
}

.coursesCard img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.coursesCard .text {
    width: 100%;
    margin-left: 25px;
    text-align: left;
}

.coursesCard .text h1 {
    font-size: 30px;
    font-weight: 500;
    line-height: 45px;
}

.coursesCard .rate {
    margin: 20px 0;
    color: #1eb2a6;
}

.coursesCard .rate i {
    font-size: 13px;
    margin-right: 5px;
}

.coursesCard .details .dimg img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 20px;
}

.coursesCard .details .box {
    display: flex;
    align-items: center;
    color: grey;
}

.coursesCard .details span {
    color: #1eb2a6;
    font-weight: 500;
    font-size: 14px;
}

.coursesCard .price {
    margin: 30px 0;
    background-color: #f8f8f8;
    padding: 10px;
}

.coursesCard h3 {
    font-weight: 500;
    color: #1eb2a6;
}