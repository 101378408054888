.faq .container {
    max-width: 70%;
    border-radius: 5px;
}

.faq .box {
    background-color: #fff;
    margin-bottom: 20px;
}

.faq button {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 15px 20px;
}

.faq h2 {
    font-weight: 500;
}

.faq p {
    padding: 20px;
}

.faq .accordion:hover {
    background-color: #2b2e74;
    color: #fff;
}